jQuery(function($){
    var $window = $(window);

    window.prettyPrint && prettyPrint();

    $('.togglable').each(function() {
        var section = $(this);
        var a = $('#toggleLink').clone().show().click(function(evt) {
            evt.preventDefault();
            a.toggleClass('toggle-hidden');
            section.fadeToggle();
            $('#payment-methods').show();
        });
        section.hide().before(a);
    });

    $('.togglable-image').each(function() {
        var section = $(this);
        var a = $('#toggleImageLink').clone().show().click(function(evt) {
            evt.preventDefault();
            a.toggleClass('toggle-hidden');
            section.fadeToggle();
        });
        section.hide().before(a);
    });

    $('.bind-affix li a').click(function(e) {
        $('.nav li.active').removeClass('active');

        let parent = $(this).parent();
        parent.addClass('active');

    });

    $(window).on('scroll', function(event) {
        var scrollValue = $(window).scrollTop();
        if (scrollValue > 1) {
            $('.bs-docs-sidenav').addClass('bs-docs-sidenav-fixed');
        } else {
            $('.bs-docs-sidenav').removeClass('bs-docs-sidenav-fixed');
        }
    });
});
